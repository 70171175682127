import React from 'react';
import {graphql} from 'gatsby';

import {useLocale} from '@app/hooks';

import {Breadcrumb, Layout, Seo, Share} from '@app/components';

import logo from '@app/assets/images/logo.png';

const SacHakkinda: React.FC = () => {
  const SCOPE_OPTIONS = {
    scope: 'pages.AboutUs',
  };
  const {t} = useLocale();

  return (
    <Layout>
      <Seo title={t('labels.metaTitle', SCOPE_OPTIONS)} />
      <div className="container mx-auto px-4 pt-20 pb-5 max-w-3xl lg:px-0 xl:max-w-3xl text-lg">
        <img className="m-auto" src={logo} width={200} />

        <h1 className="mt-20 mb-5 font-bold text-center">Saç Hakkında</h1>

        <div className="who-am-i-description">
          <p className="mb-4 m-auto text-center mb-20">
            Saç kökü ile greft sıkça karıştırılan terimlerdir. Saç kökü;
            kafatasında yer alan yağ dokusuna gömülü halde olan, etrafı saç
            üreten hücrelerle çevrili saçın dip kısmını içeren bir yapıdır.
          </p>

          <h2 className="mt-20 mb-5 font-bold text-center">
            Saç telinin kökünde büyüme evreleri nelerdir?
          </h2>

          <p className="mb-4 m-auto text-center mb-5">
            Saç Kılının Büyüme Evreleri:Saç folikülünde saç telinin büyümesi
            sürekli dönüşüm içinde olan bir süreçtir. Saç büyüme evreleri her
            saç folikülünde devam eder, bu nedenle her saç kökü farklı evrelerde
            bulunur.
          </p>

          <p className="mb-4 m-auto text-center mb-5">
            <b>1-Anojen Faz:</b> Bu faz büyüme aşamasıdır. Saç köklerinin
            yaklaşık olarak % 85’i bu safhadadır. Anojen fazı yaklaşık 2- 6 yıl
            arasında bir süreçtir. Saç telleri bu süreçte yılda yaklaşık 10 cm
            uzayabilir.
          </p>

          <p className="mb-4 m-auto text-center mb-5">
            <b>2-Katojen Faz:</b> Bu süreç bir geçiş fazıdır. Büyüme süreci
            sonrası saç kökleri 1-3 haftalık bir süreç içinde geçen saç
            folikülünün gerileme sürecidir. Bu süreçte dermal papilla geriler ve
            bozulur. Saç foliküllerinin yaklaşık %1-2 si bu süreçtedir.
          </p>

          <p className="mb-4 m-auto text-center mb-5">
            <b>3-Telojen Faz:</b> Saç köklerinin dinlenme fazıdır ve yaklaşık
            olarak 2-3 aylık bir süreçtir. Bu süreçte dermal papilla bozulmaz ve
            saç köküne bağlı bir şekilde bekler. Tüm saçların yaklaşık % 13’i bu
            safhadadır. Bu süreç bitince anojen fazı tekrar başlar. Dermal
            papilla ve saç follikülü yeniden bağlantı oluştur ve saç üretimini
            yeniden başlatır. Bu aşamada dökülen saç kılının yerine yenileri
            büyümeye başlar.
          </p>

          <p className="mb-4 m-auto text-center mb-20">
            Greft; saç köklerinin yer aldığı saç derisinden çıkarılan yapıdır.
            Her greft içerisinde en az bir en fazla 4-5 adet saç kökü
            bulunmaktadır. Greftler donör bölgeden alınıp ekim yapılacak alan
            için hazırlanmaktadır. Genellikle ekim işlemi öncesinde doktor
            hastanın ekim tedavisinde kaç adet grefte ihtiyacı olduğunu
            belirler. Her kişide greft sayısı farklılık gösteren bir konudur.
            Saç ekimi tedavi yönteminde greftler genellikle iki kulak arası
            donör bölgeden alınmaktadır. Greftler farklı büyüklük, şekil ve kök
            sayısı açısından farklılık gösterebilmektedir.
          </p>
        </div>
      </div>
    </Layout>
  );
};

export default SacHakkinda;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
